<template>
  <v-container
    fluid
    fill-height
    style="height: 100%"
    class="d-flex flex-column"
  >
    <!-- {{ defaultTimeZone }} -->
    <v-card outlined class="pa-6" width="100%">
      <GsLocale :value="defaultLocale" @change="language_changed" />
      <GsTimeZone :value="defaultTimeZone" @change="timezone_changed" />
    </v-card>
    <div class="mt-auto">
      <v-divider />
      <span>{{ $t("version") }}: </span>
      <span>{{ version }}</span>
    </div>
  </v-container>
</template>

<script>
import { GsLocale, GsTimeZone } from "ngt-frontend-core";

export default {
  components: {
    GsLocale,
    GsTimeZone
  },
  data() {
    return {};
  },
  computed: {
    defaultLocale() {
      return this.getDefaultLocale();
    },
    defaultTimeZone() {
      return this.getDefaultTimeZone();
    },
    version() {
      return process.env.VUE_APP_VERSION || 0;
    }
  },
  methods: {
    language_changed(locale) {
      this.$EventBus.$emit("buildMenuItems");
      this.$router.push({ name: "Home" });
    },
    timezone_changed(locale) {
      // this.$router.push({ name: "Home" });
    }
  }
};
</script>
